<template>
    <div class="message_management">
        <el-tabs type="card" v-model="editableTabsValue" tab-position="left" class="menu" @tab-click="tabChange">
            <el-tab-pane disabled>
                <!-- 头像 -->
                <span slot="label" class="menu_icon">
                    <el-popover
                        placement="right"
                        width="320"
                        trigger="click">
                        <div style="display: flex;padding: 15px">
                            <div style="flex: 0.8">
                                <div style="font-size: 20px;margin-bottom: 5px">{{personalDetails.jwucKfNick}}</div>
                                <div style="color: #999999">微信号：{{personalDetails.jwucKfWx}}</div>
                            </div>
                            <div style="flex: 0.2">
                                <el-avatar slot="reference" shape="square" :size="60" :src="personalDetails.jwucKfHeadUrl"></el-avatar>
                                <el-button size="mini" style="padding:4px 5px" @click="editAvatar">修改头像</el-button>
                            </div>
                        </div>
                        <el-avatar slot="reference" shape="square" :src="personalDetails.jwucKfHeadUrl" style="margin-top: 30px;cursor: pointer"></el-avatar>
                    </el-popover>
                </span>
            </el-tab-pane>
            <el-tab-pane name="1">
                <span slot="label" class="menu_icon">
                    <img src="../../assets/weChat/wechat_click.png" style="margin-top: 30px" v-if="editableTabsValue == '1'" />
                    <img src="../../assets/weChat/wechat.png" style="margin-top: 30px" v-else />
                </span>
                <el-container v-loading="loading">
                    <el-header class="header">
                        <!-- 搜索start -->
                        <div class="search">
                            <el-row type="flex" class="row-bg" justify="center">
                                <el-col :span="23">
                                    <el-input placeholder="搜索" class="search_input" clearable size="mini" v-model="findNickName" @change="getSwitchInCustomerList">
                                        <i class="el-input__icon el-icon-search"
                                            slot="prefix">
                                        </i>
                                    </el-input>
                                </el-col>
                            </el-row>
                        </div>
                        <!-- 搜索end -->
                        <div class="name">
                            <!-- 昵称 -->
                            <span class="nick_name">{{nickname}}</span>
                            <!-- 结束会话 -->
                            <div class="chat_header_icons" v-show="messageIndex !== ''">
                                <el-tooltip effect="dark" content="转接客服" placement="bottom-end">
                                    <span class="chat_header_icon transfer" @click="switchService"></span>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="结束会话" placement="bottom-end">
                                    <span class="chat_header_icon exit" @click="switchOut"></span>
                                </el-tooltip>
                            </div>
                        </div>
                    </el-header>
                    <el-container>
                        <!-- 消息列表start -->
                        <el-aside width="280px" class="message_list">
                            <div class="message" 
                                v-for="(item, index) in switchInCustomerList" 
                                :key="index" 
                                @click="showDetail(index, item)" 
                                @contextmenu.prevent="onContextmenu(index)"
                                :style="(messageIndex == index && messageIndex !== '') ? {'background': '#c9c8c7'} : {}">
                                <!-- 右键菜单 -->
                                <vue-context-menu
                                    :contextMenuData="contextMenuData"
                                    :transferIndex="transferIndex"
                                    @Handler1="Handler_A(item)"
                                ></vue-context-menu>
                                <img :src="item.headimgurl" />
                                <div class="user_name"><span>{{item.nickname}}</span><time>{{getLastTime(item.jwcnCreateTime)}}</time></div>
                                <div class="msg_lessen">{{item.content}}</div>
                                <CountDown :show-day="false" @timeup="autoSwitchOut(item)" :minute="item.minute" :second="item.second" v-if="item.update" v-show="false"></CountDown>
                            </div>
                        </el-aside>
                        <!-- 消息列表end -->
                        <el-container v-show="messageIndex !== ''">
                            <!-- 聊天记录start -->
                            <el-main class="main" id="main" @scroll="handleScroll">
                                <!-- 加载 -->
                                <img src="../../assets/weChat/loading.gif" class="loading" v-if="receive.pages > receive.current">
                                <div v-for="(item, index) in receiveAndReply" :key="index" @contextmenu.prevent="turnTheText(item, index)">
                                    <!-- 右键菜单 -->
                                    <vue-context-menu
                                        :contextMenuData="contextMenuData2"
                                        :transferIndex="transferIndex2"
                                        @Handler2="Handler_B(item)"
                                        @Handler3="Handler_C(item)"
                                    ></vue-context-menu>
                                    <!-- 接收的消息 -->
                                    <div v-if="item.type == 'receive'">
                                        <div align="center" style="padding: 15px 0;">
                                            <el-tag type="info" size="mini">{{item.createTime}}</el-tag>
                                        </div>
                                        <el-avatar shape="square" :src="item.headimgurl" style="width: 37px;height: 37px;float: left;margin-right: 13px"></el-avatar>
                                        <div class="chatPop1">
                                            <p v-if="item.jwcnMsgType === 'text'" style="line-height: 25px;min-height: 21px" v-html="imgChangeEmoji(item.content)"></p>
                                            <el-image v-else-if="item.jwcnMsgType === 'image'" :src="item.image" :preview-src-list="[item.image]" class="img"></el-image>
                                            <video v-else-if="item.jwcnMsgType === 'video'" controls="controls" preload="metadata" :src="item.videoUrl"></video>
                                            <div v-else-if="item.jwcnMsgType === 'voice'" style="line-height: 25px;min-height: 21px;cursor: pointer;" class="voice" @click="playAudio(index, item.voiceUrl)">
                                                <!-- 语音喇叭 -->
                                                <div :class="btnStatus && voiceIndex == index ? 'static voice' : 'wave voice'" :style="'margin-right:'+(item.voiceTime/60*200+47)+'px;'">
                                                    <div class="wifi-circle first"></div>
                                                    <div class="wifi-circle second"></div>
                                                    <div class="wifi-circle third"></div>
                                                </div>
                                                <div style="border-top:1px solid #ccc;" v-show="showTextList.indexOf(index) > -1">{{item.content}}</div>
                                                <div id="audioBody"></div>
                                            </div>
                                            <!-- 语音时长 -->
                                            <span v-if="item.jwcnMsgType === 'voice'" class="voice_time">{{item.voiceTime}}''</span> 
                                        </div>
                                    </div>
                                    <!-- 回复的消息 -->
                                    <div v-else>
                                        <div align="center" style="padding: 15px 0;">
                                            <el-tag type="info" size="mini">{{item.createTime}}</el-tag>
                                        </div>
                                        <el-row>
                                            <el-col :span="23">
                                                <div class="chatPop2" >
                                                    <p v-if="item.content" style="line-height: 25px;min-height: 21px" v-html="imgChangeEmoji(item.content)"></p>
                                                    <el-image :src="item.image" :preview-src-list="[item.image]" v-else class="img"></el-image>
                                                </div>
                                            </el-col>
                                            <el-col :span="1">
                                                <el-avatar shape="square" :src="item.headimgurl" style="width: 37px;height: 37px;"></el-avatar>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </el-main>
                            <!-- 聊天记录end -->
                            <!-- 输入框start -->
                            <el-footer class="footer">
                                <div class="reply_button">
                                    <el-popover
                                        placement="top"
                                        width="392"
                                        trigger="click">
                                        <el-tabs type="border-card">
                                            <el-tab-pane label="QQ表情">
                                                <div style="height: 203px">
                                                    <span v-for="(item, count) in qq_emoji" :key="count">
                                                        <img :src="require('./qq-emoji/'+count+'.gif')" @click="sellectQQEmoji(count)" style="cursor: pointer;" :title="item.web_code">
                                                    </span>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane label="符号表情">
                                                <div style="height: 203px;overflow-y: scroll;font-size: 18px">
                                                    <span v-for="(item, count) in wx_emoji" :key="count" >
                                                        <div @click="sellectWXEmoji(count)" style="cursor: pointer;width: 24px;height: 24px;float:left" :title="item.web_code">{{ item.code }}</div>
                                                    </span>
                                                </div>
                                            </el-tab-pane>
                                        </el-tabs>
                                        <span slot="reference" class="reply_emoji"></span>
                                    </el-popover>
                                    <el-upload
                                        :data="picData"
                                        class="upload-demo"
                                        action="https://upload-z2.qiniup.com"
                                        :before-upload="beforeUpload"
                                        :on-success="success"
                                        :show-file-list="false"
                                        accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.GIF,.PNG">
                                        <span class="reply_image"></span>
                                    </el-upload>
                                    <span class="screenshot" @click="startCaptureClick"></span>
                                    <el-popover
                                        placement="bottom"
                                        trigger="click">
                                        <el-checkbox v-model="checked" :checked="checked" @change="settingScreenshot">截图时隐藏当前窗口</el-checkbox>
                                        <span slot="reference" class="down"></span>
                                    </el-popover>
                                </div>
                                <div class="reply_content">
                                    <el-input
                                        maxlength="600"
                                        v-model="replyContent"
                                        type="textarea"
                                        :rows="4">
                                    </el-input>
                                </div>
                                <div class="reply_submit">
                                    <el-button size="mini" @click="submit" @keyup.enter.native="submit">发送</el-button>
                                </div>
                            </el-footer>
                            <!-- 输入框end -->
                        </el-container>
                        <el-container v-show="messageIndex === ''" class="no_msg">
                            <img src="../../assets/weChat/wechat2.png">
                            <span>未选择聊天</span>
                        </el-container>
                    </el-container>
                </el-container>
            </el-tab-pane>
            <el-tab-pane name="2">
                <span slot="label" class="menu_icon">
                    <img src="../../assets/weChat/user_click.png" v-if="editableTabsValue == '2'" />
                    <img src="../../assets/weChat/user.png" v-else />
                </span>
                <el-container v-loading="loading">
                    <el-aside width="280px" class="fans_list">
                        <!-- 搜索start -->
                        <div class="search">
                            <el-row type="flex" class="row-bg" justify="center">
                                <el-col :span="23">
                                    <el-input placeholder="搜索" class="search_input" clearable size="mini" v-model="findFans" @change="getFansList">
                                        <i class="el-input__icon el-icon-search"
                                            slot="prefix">
                                        </i>
                                    </el-input>
                                </el-col>
                            </el-row>
                        </div>
                        <!-- 搜索end -->
                        <van-index-bar :index-list="indexList" class="list">
                            <span v-for="(item, index) in fansList" :key="index">
                                <van-index-anchor :index="item.key" /><!-- 分组 -->
                                <div v-for="(item2, index2) in fansList[index].data" :key="index2" :class="item2.fanId === fanIndex.fanId ? 'fans_col fans_col_click' : 'fans_col'" @click="fanIndex = item2"><!-- 分组下的粉丝 -->
                                    <el-avatar shape="square" size="large" :src="item2.headimgurl" class="fans_avatar"></el-avatar><!-- 粉丝头像 -->
                                    <span class="fan_name">{{item2.nickname}}</span><!-- 粉丝昵称 -->
                                </div>
                            </span>
                        </van-index-bar>
                    </el-aside>
                    <el-main class="has_fan" v-if="fanIndex">
                        <div class="fan_data">
                            <el-avatar shape="square" :size="70" :src="fanIndex.headimgurl" class="fans_avatar"></el-avatar><!-- 粉丝头像 -->
                            <el-form label-position="left">
                                <el-form-item label="昵称" label-width="70px">
                                    {{fanIndex.nickname}}
                                </el-form-item>
                                <el-form-item label="性别" label-width="70px">
                                    <span v-if="fanIndex.sex === 1" class="man">♂</span>
                                    <span v-else-if="fanIndex.sex === 2" class="woman">♀</span>
                                    <span v-else></span>
                                </el-form-item>
                                <el-form-item label="地区" label-width="70px">
                                    {{fanIndex.province + ' ' + fanIndex.city}}
                                </el-form-item>
                                <el-form-item label="粉丝编号" label-width="70px">
                                    {{fanIndex.openid}}
                                </el-form-item>
                                <el-form-item label="标签" label-width="70px">
                                    <el-tag v-for="(count, index) in fanIndex.tagName_list" :key="index" style="margin-right: 10px">{{count}}</el-tag>
                                    <el-tag v-show="fanIndex.tagName_list.length === 0" style="margin-right: 10px">无标签</el-tag>
                                    <el-button type="primary" icon="el-icon-edit" circle @click="tagShow" size="mini"></el-button>
                                </el-form-item>
                                <el-form-item label="备注" label-width="70px">
                                    {{fanIndex.remark || '未填写'}}
                                    <el-button type="primary" icon="el-icon-edit" circle @click="openRemarkDia" size="mini"></el-button>
                                </el-form-item>
                                <el-form-item label="同步信息" label-width="70px">
                                    <el-button type="primary" icon="el-icon-refresh-left" circle size="mini" @click="synchronous"></el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-main>
                    <el-main class="no_fan" v-else>
                        <img src="../../assets/weChat/wechat2.png">
                    </el-main>
                </el-container>
            </el-tab-pane>
            <!-- 未接入的消息列表start -->
            <el-tab-pane name="3">
                <span slot="label" class="menu_icon">
                    <img src="../../assets/weChat/history_click.png" v-if="editableTabsValue == '3'" />
                    <img src="../../assets/weChat/history.png" v-else />
                </span>
                <el-row class="waiting_panel">
                    <el-col :span="12" style="height: 28px">
                        <a :class="accessState == '0' ? 'setting_panel selected' : 'setting_panel'" @click="tabSwitch('待接入')">待接入</a>
                        <a :class="accessState == '1' ? 'setting_panel selected' : 'setting_panel'" @click="tabSwitch('已过期')">已过期</a>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="success" class="switch_btn" size="mini" @click="switchIn(switchInList)" v-show="accessState === '0'">接入</el-button><!--批量接入-->
                    </el-col>
                </el-row>
                <el-table
                    v-loading="loading"
                    :data="customerList"
                    style="width: 100%;margin-left: 2px"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="客户头像">
                        <template slot-scope="scope">
                            <img :src="scope.row.headimgurl" style="width: 50px;height: 50px">
                        </template>
                    </el-table-column>
                    <el-table-column prop="nickname" label="客户昵称"></el-table-column>
                    <el-table-column label="最新消息">
                        <template slot-scope="scope">
                            [{{scope.row.msgNum}}条]{{scope.row.content}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="jwcnCreateTime" label="消息时间"></el-table-column>
                    <el-table-column label="操作" v-if="accessState === '0'">
                        <template slot-scope="scope">
                            <span class="switch_in" @click="switchIn(String(scope.row.jwcnFanId))">接入</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <!-- 未接入的消息列表end -->
        </el-tabs>
        <el-dialog v-dialogDrag width="680px" title="请选择要转接的客服" @close="closeSwitchServiceDialog" :visible.sync="switchServiceDialog" :close-on-click-modal="false" v-loading="loading">
            <el-row>
                <el-col :span="8" :gutter="12" class="card_row" v-for="(item, index) in serviceList" :key="index">
                    <div :class="serviceIndex === index ? 'service_card selected' : 'service_card'" @click="clickService(index, item.jwucUserId)">
                        <div class="service_avatar"><el-avatar shape="square" :size="54" :src="item.jwucKfHeadUrl"></el-avatar></div>
                        <div class="service_detail">
                            <div class="service_nickname">{{item.jwucKfNick}}</div>
                            <div class="service_wx">{{item.jwucKfWx}}</div>
                        </div>
                        <div class="selected_icon" v-show="serviceIndex === index"></div>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer">
                <el-button type="success" size="small" @click="transfer">转接</el-button>
                <el-button @click="switchServiceDialog = false" size="small">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            v-loading="loading"
            v-dialogDrag
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            title="修改标签"
            :visible.sync="dialogVisible"
            width="500px">
            <el-checkbox-group v-model="fanIndex.tagid_list" :max="20">
                <el-checkbox v-for="tag in tagsList" :label="tag.tagId" :key="tag.tagId">{{tag.tagName}}</el-checkbox>
            </el-checkbox-group>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            v-dialogDrag
            v-loading="loading"
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            title="修改备注"
            :visible.sync="remarkDia"
            @close="closeRemarkDia"
            width="500px">
            <el-input v-model="remark"></el-input>
            <div slot="footer">
                <el-button @click="remarkDia = false">取 消</el-button>
                <el-button type="primary" @click="updateRemark">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="提示"
            :visible.sync="hint"
            width="30%">
            <span>
                如果超过5秒无响应，按F5刷新，或请点此
                <a target="_blank" :href="'http://www.ggniu.cn/download/CaptureInstall.exe?' + date.getMinutes() + date.getSeconds()" @click="hint = false">安装</a>，
                安装完成后，按F5刷新。
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hint = false">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="imageDialog" v-loading="loading" width="740px">
            <el-image :src="imageUrl" style="width: 700px" lazy></el-image>
            <span slot="footer" class="dialog-footer">
                <el-button @click="imageDialog = false">取 消</el-button>
                <el-button type="primary" @click="success(imageUrl, null, null, true)">发 送</el-button>
            </span>
        </el-dialog>

        <el-dialog title="修改头像" :visible.sync="editAvatarDialog" v-loading="loading" width="800px">
            <el-row type="flex" justify="center">
                <div class="cropper">
                    <vueCropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.size"
                        :outputType="option.outputType"
                        :info="option.info"
                        :full="option.full"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :fixedBox="option.fixedBox"
                        @realTime="realTime"
                    ></vueCropper>
                </div>
                <div class="previewBox">
                    <div :style="previews.div" class="preview">
                        <img :src="previews.url" :style="previews.img">
                    </div>
                    <el-row type="flex" justify="center">
                        <el-upload
                            action=""
                            :show-file-list="false"
                            :auto-upload="false"
                            :on-change="uploadImg"
                        >
                            <el-button size="mini" type="primary">更换头像</el-button>
                        </el-upload>
                    </el-row>
                    <br>
                    <el-row>
                        <el-button icon="el-icon-plus" circle size="mini"
                                @click="changeScale(1)"></el-button>
                        <el-button icon="el-icon-minus" circle size="mini"
                                @click="changeScale(-1)"></el-button>
                        <el-button icon="el-icon-download" circle size="mini"
                                @click="down('blob')"></el-button>
                        <el-button icon="el-icon-refresh-left" circle size="mini"
                                @click="rotateLeft"></el-button>
                        <el-button icon="el-icon-refresh-right" circle size="mini"
                                @click="rotateRight"></el-button>
                    </el-row>
                </div>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editAvatarDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveEditAvatar">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import emoji from '../../assets/weChat/emoji'
import moment from 'moment'
import SWF from '../../assets/weChat/sensitiveWords'
import CountDown from './components/CountDown' // 自动结束会话倒计时
import cnchar from 'cnchar';
import 'vant/lib/index.css'
import { Init, StartCapture } from './api/capturewrapper'
import {VueCropper} from 'vue-cropper'
var compare = function (prop) { // 比较两个日期
    return function (obj1, obj2) {
        var val1 = new Date(obj1[prop]);
        var val2 = new Date(obj2[prop]);
        if (val1 < val2) {
            return -1;
        } else if (val1 > val2) {
            return 1;
        } else {
            return 0;
        }            
    } 
}
export default {
    data() {
        return {
            editableTabsValue: '1', //菜单编号
            messageIndex: '', //选择聊天
            replyContent: '', //回复内容
            customerList: [], //未接入列表
            switchInCustomerList: [], //已接入列表
            receive: {  //接收消息
                list: [],
                limit: 8,
                cursor: 1,
                pages: '',
                current: '',
                startId: '',
                endId: -1,
            },
            reply: { //回复消息
                list: []
            },
            receiveAndReply: [],
            nickname: '', //用户昵称
            openid: '',
            loadMore: true, //加载更多图标
            scrollHeight: '', 
            jwcnId: '',
            switchInList: '', //多个接入
            fanId: '', //粉丝id
            findNickName: '', //搜索消息列表
            websocket: '',  
            accessState: '0', // 0是查询未接入，1是查询已过期
            fansList: [], // 通讯录列表
            indexList: [], // 分类集合
            fanIndex: '', // 选择粉丝下标
            findFans: '',
            qq_emoji: emoji['QQ_EMOJI'],
            wx_emoji: emoji['WX_EMOJI'],
            switchServiceDialog: false, // 转接客服弹窗
            serviceList: [],    // 在线客服列表
            serviceIndex: '',   // 选择在线客服
            jwucUserId: '', 
            tagsList: [], // 标签列表
            dialogVisible: false, //修改标签弹窗
            remarkDia: false, // 备注弹窗
            remark: '', // 备注
            personalDetails: {
                jwucUserId: '',
                jwucKfNick: '',
                jwucKfWx: '',
                jwucKfHeadUrl: '',
            }, // 客服个人信息
            picData: {
                //上传图片携带的参数
                token: ''
            },
            hint: false, // 提示用户下载控件
            date: new Date(),
            imageDialog: false, // 截屏后展示图片
            loading: false,
            checked: sessionStorage.getItem('checked') === 'false' ? false : true, // 截图时是否隐藏当前窗口
            btnStatus: false, // 喇叭状态
            voiceIndex: '', // 播放的语音
            voiceIndex1: '', // 语音转文字
            showTextList: [],
            contextMenuData: { // 右键菜单
                axis: {
                    x: null,
                    y: null
                },
                menulists: [
                    {
                        btnName: '结束会话',
                        fnHandler: "Handler1"
                    }
                ]
            },
            transferIndex: null,
            contextMenuData2: { // 右键菜单
                axis: {
                    x: null,
                    y: null
                },
                menulists: [{btnName: '语音转文字',fnHandler: "Handler2"}]
            },
            transferIndex2: null,

            avatarURL: '',
            editAvatarDialog: false,
            previews: {},
            option: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 剪切后的图片质量（0.1-1）
                full: true, // 输出原图比例截图 props名full
                outputType: 'png', // 裁剪生成额图片的格式
                canMove: true,  // 能否拖动图片
                original: false,  // 上传图片是否显示原始宽高
                canMoveBox: true,  // 能否拖动截图框
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 150,
                autoCropHeight: 150,
                fixedBox: true // 截图框固定大小
            },
        };
    },
    components: {
        CountDown,
        VueCropper
    },
    created() {
        Init() // 初始化截图控件
        this.getSwitchInCustomerList()
        this.getTagsList()
        this.getPersonalDetails()
        // const url = `ws://nts1.fzz1.cn:10068/wss/channel?token=${sessionStorage.getItem('token')}`
        const url = process.env.VUE_APP_TITLE == 'online' ? 
                    `wss://api.86xc.net/wss/channel?token=${sessionStorage.getItem('token')}` : 
                    `ws://nts1.fzz1.cn:10068/wss/channel?token=${sessionStorage.getItem('token')}`
        // const url = `ws://172.18.88.15:7070/middleware/channel?token=${sessionStorage.getItem('token')}`
        this.websocket = new WebSocket(url)
        // 连接成功建立的回调方法
        this.websocket.onopen = function(event){
            console.log('WebSocket连接成功')
        }
        //接收到消息的回调方法
        this.websocket.onmessage = (event) => {
            console.log(JSON.parse(event.data),'111111')
            let data = JSON.parse(JSON.parse(event.data).msg)
            if (JSON.parse(event.data).sid) { // 接收已接入消息
                if (JSON.parse(event.data).sid === this.personalDetails.jwucUserId) {
                    let flag = true
                    for (let item of this.switchInCustomerList) {
                        if (item.jwcnFromUserName == data.FromUserName) {
                            item.content = this.showMsgType(data.MsgType, data.Content)
                            item.jwcnCreateTime = data.sendTime.replace('T', ' ')
                            item.minute = 30
                            item.second = 0
                            item.update = false
                            this.$nextTick(() => {
                                item.update = true
                            })
                            flag = false
                        }
                    }
                    if (flag) {
                        this.switchInCustomerList.unshift({
                            jwcnFromUserName: data.FromUserName,
                            content: this.showMsgType(data.MsgType, data.Content),
                            jwcnCreateTime: data.sendTime.replace('T', ' '),
                            minute: 30,
                            second: 0,
                        })
                    }
                }
            } else { // 接收未接入消息
                let isNew = true
                for (let item of this.customerList) { // 更新未接入消息的数据
                    if (item.jwcnFromUserName == data.FromUserName) {
                        item.content = this.showMsgType(data.MsgType, data.Content),
                        item.jwcnCreateTime = data.sendTime.replace('T', ' ')
                        item.msgNum ++
                        isNew = false
                    }
                }
                if (isNew) { // 新增的未接入的数据
                    this.customerList.unshift({
                        headimgurl: data.headimgurl,
                        nickname: data.nickname,
                        content: data.Content,
                        jwcnCreateTime: data.sendTime.replace('T', ' '),
                        msgNum: 1,
                        jwcnFanId: JSON.parse(event.data).fanId,
                        nickname: JSON.parse(event.data).nickname,
                        jwcnFromUserName: data.FromUserName
                    })
                }
            }
            if (this.openid == data.FromUserName) { // 接收聊天窗口消息
                this.receiveAndReply.push({
                    type: 'receive',
                    createTime: data.sendTime.replace('T', ' '),
                    content: data.Content || data.Recognition,
                    headimgurl: data.headimgurl,
                    image: data.PicUrl,
                    jwcnMsgType: data.MsgType,
                    voiceUrl: data.voiceUrl,
                    voiceTime: data.voiceTime,
                    videoUrl: data.videoUrl
                })
                this.toBottom()
            }
            this.switchInCustomerList.sort(compare("jwcnCreateTime")).reverse() // 更新消息列表后，按照时间顺序进行排序
            for (let index in this.switchInCustomerList) {  // 时间排序之后，消息列表顺序有变更时，选中的消息列表的下标也跟着改变
                if (this.switchInCustomerList[index].jwcnFromUserName == this.openid) {
                    this.messageIndex = index
                }
            }
        }
        document.onkeydown = (e) => {
            if (e.keyCode === 13 && this.messageIndex !== '') {
                this.submit()
                e.preventDefault();
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
    },
    computed: {
        imageUrl: function() {
            return this.$store.state.imageUrl
        }
    },
    watch: {
        imageUrl() { // 截屏完成后展示图片
            this.imageDialog = true;
        },
    },
    methods: {
        startCaptureClick() { // hint为ture时，提示用户下载控件
            this.hint = StartCapture(this.checked)
        },
        imgChangeEmoji(str) { // emoji代码替换成图片，在页面展示
            this.qq_emoji.forEach(element => {
                if (str && element.code && str.indexOf(element.code) > -1) {
                    str = this.getImgPosition(element, element.code, str)
                } else if (str && element.emoji && str.indexOf(element.emoji) > -1) {
                    str = this.getImgPosition(element, element.emoji, str)
                } else if(str && str.indexOf(element.cn) > -1) {
                    str = this.getImgPosition(element, element.cn, str)
                } else if(str && str.indexOf(element.us) > -1 && element.us !='' && element.us !='[]' ){
                    str = this.getImgPosition(element, element.us, str)
                }
            });
            return str;
        },
        getImgPosition(element, field, str) { // 获得图片路径
            const effectCode = field.replace(/[.\\[\]{}()|^$?*+]/g, '\\$&'); // 转义字符串中的元字符
            const pattern = new RegExp(effectCode, 'g');
            let image = require(`./qq-emoji/${element.id}.gif`)
            const imgUrl = `<img style="width: 24px;height: 24px;overflow: hidden;" src="${image}" />`;
            return str.replace(pattern, imgUrl);
        },
        tabChange(e) { // 切换菜单
            this.editableTabsValue = e.paneName
            if (e.paneName == '1') {
                this.getSwitchInCustomerList()
            } else if (e.paneName == '2') {
                this.getFansList()
            } else if (e.paneName == '3') {
                this.tabSwitch('待接入')
            }
        },
        showDetail(index, item) { // 点击消息列表
            if (this.messageIndex === index) {
                return
            }
            this.nickname = item.nickname
            this.openid = item.jwcnFromUserName
            this.fanId = item.jwcnFanId
            this.jwcnId = item.jwcnId
            this.messageIndex = index
            this.receive.startId = ''
            this.receive.endId = '-1'
            this.receive.cursor = 1
            this.receive.limit = 8
            this.loadMore = true
            this.showTextList = []
            this.getDetailList().then(res => {
                this.receive.startId = this.receive.list[this.receive.list.length-1].jwcnId
                this.getReplyList().then(res => {
                    this.receiveAndReply = [...this.receive.list, ...this.reply.list]
                    this.receiveAndReply.sort(compare("createTime"))
                    this.toBottom()
                })
            })
        },
        sellectQQEmoji(index) {
            this.replyContent += this.qq_emoji[index].cn
        },
        sellectWXEmoji(index) {
            this.replyContent += this.wx_emoji[index].code
        },
        submit() { // 发送
            let ts = new SWF();
            ts.builtSWL();
            this.replyContent = ts.filter(this.replyContent);
            let reg = new RegExp('^[ ]+$')
            if(this.replyContent && !reg.test(this.replyContent)){
                this.$axios({
                    method: 'post',
                    url: '/common3/wxCustomerNews/sendMessage',
                    data: {
                        touser: this.openid,
                        jwcnId: this.jwcnId,
                        msgtype: 'text',
                        text: {
                            content: this.replyContent
                        }
                    }
                }).then(res => {
                    this.receiveAndReply.push({createTime: moment().format("YYYY-MM-DD HH:mm:ss"), content: this.replyContent, headimgurl: this.personalDetails.jwucKfHeadUrl, jwcnMsgType: 'text'})
                    this.replyContent = ''
                    for (let item of this.switchInCustomerList) { // 发送成功后刷新自动结束会话时间
                        if (item.jwcnFromUserName == this.openid) {
                            item.minute = 30
                            item.second = 0
                            item.update = false
                            this.$nextTick(() => { // 解决父组件传值给子组件，父组件值改变，子组件不能重新渲染的问题
                                item.update = true
                            })
                        }
                    }
                }).catch(err => {
                    this.isError(err, this);
                }).finally(() => {
                    this.toBottom()
                })
            } else {
                this.$message.warning('不能发送空白消息')
            }
        },
        getSwitchInCustomerList() { // 查询已接入用户列表
            this.loading = true
            this.$axios({
                method: 'get',
                url: '/common3/wxCustomerNews/NewSend',
                params: {
                    jwcnUserId: sessionStorage.getItem('userCoding'),
                    nickname: this.findNickName,
                }
            }).then(res => {
                let remove = true
                for (let index in res.data.result) {
                    let content = JSON.parse(res.data.result[index].jwcnContent).Content
                    res.data.result[index].content = this.showMsgType(res.data.result[index].jwcnMsgType, content)
                    res.data.result[index].update = true
                    if (this.fanId === res.data.result[index].jwcnFanId) {
                        remove = false
                        this.messageIndex = index
                    }
                }
                if (remove) {
                    this.messageIndex = ''
                    this.nickname = ''
                }
                this.switchInCustomerList = res.data.result
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        getDetailList() { // 查询接收消息
            return this.$axios({
                method: 'get',
                url: '/common3/wxCustomerNews/receive',
                params: {
                    jwcnFanId: this.fanId,
                    cursor: this.receive.cursor,
                    limit: this.receive.limit
                }
            }).then(res => {
                for (let item of res.data.result.records) {
                    item.createTime = item.jwcnCreateTime
                    item.type = 'receive'
                    item.content = JSON.parse(item.jwcnContent).Content || JSON.parse(item.jwcnContent).Recognition
                    item.image = JSON.parse(item.jwcnContent).PicUrl
                    item.voiceUrl = JSON.parse(item.jwcnContent).voiceUrl
                    item.videoUrl = JSON.parse(item.jwcnContent).videoUrl
                    item.voiceTime = JSON.parse(item.jwcnContent).voiceTime
                }
                this.receive.list = res.data.result.records
                this.receive.pages = res.data.result.pages
                this.receive.current = res.data.result.current
                // 下拉刷新后，语音转文字的列表下标发生了改变
                this.showTextList = res.data.result.size ? this.showTextList.map((i) => {
                    return i + res.data.result.size
                }) : this.showTextList
            }).catch(err => {
                this.isError(err, this);
            })
        },
        getReplyList() { // 查询回复消息
            return this.$axios({
                method: 'get',
                url: '/common3/wxCustomerNews/reply',
                params: {
                    openid: this.openid,
                    startId: this.receive.startId,
                    endId: this.receive.endId,
                }
            }).then(res => {
                for (let item of res.data.result) {
                    item.createTime = item.jwrnCreateTime
                    item.type = 'reply'
                    item.content = JSON.parse(item.jwrnContent).content
                    item.image = JSON.parse(item.jwrnContent).picurl
                }
                this.reply.list = res.data.result
                // 下拉刷新后，语音转文字的列表下标发生了改变
                this.showTextList = res.data.result.length ? this.showTextList.map((i) => {
                    return i + res.data.result.length
                }) : this.showTextList
            }).catch(err => {
                this.isError(err, this);
            })
        },
        toBottom() { // 回到底部
            this.$nextTick(() => {
                var scrollTarget = document.getElementById("main");
                //scrollTarget.scrollHeight是获取dom元素的高度，然后设置scrollTop
                setTimeout(() => {
                    scrollTarget.scrollTop=scrollTarget.scrollHeight;
                }, 300)
            })
        },
        goBack() { // 回到原位
            this.$nextTick(() => {
                setTimeout(() => {
                var scrollTarget = document.getElementById("main");
                    scrollTarget.scrollTop=scrollTarget.scrollTop+scrollTarget.scrollHeight-this.scrollHeight;
                }, 100)
            })
        },
        handleScroll() { // 下拉加载
            var scrollTarget = document.getElementById("main");
            this.scrollHeight=scrollTarget.scrollHeight
            if (scrollTarget.scrollTop < 50 && this.loadMore) {
                this.loadMore = false
                if (this.receive.pages > this.receive.current) {
                    this.receive.cursor ++
                    this.getDetailList().then(() => {
                        this.receive.endId = this.receive.startId
                        this.receive.startId = this.receive.list[this.receive.list.length-1].jwcnId
                        this.getReplyList().then(() => {
                            this.receiveAndReply = [...this.receive.list, ...this.receiveAndReply, ...this.reply.list]
                            this.receiveAndReply.sort(compare("createTime"))
                            this.loadMore = true
                            this.goBack()
                        })
                    })
                }
            }
        },
        handleSelectionChange(val) { // 多选，批量接入
            console.log(val)
            let list = JSON.parse(JSON.stringify(val))
            let fansIdList = [];
            for (let item of list) {
                fansIdList.push(item.jwcnFanId)
            }
            this.switchInList = fansIdList.join(',')
        },
        switchIn(list) { // 接入
            if (!list) return
            this.loading = true
            this.$axios({
                method: 'post',
                url: '/common3/wxUserCustomer/accessCustomer',
                params: {
                    jwucCustomerIds: list,
                }
            }).then(res => {
                this.tabSwitch('待接入')
                let list = []
                for (let item of res.data.result) {
                    list.push(item.nickname)
                }
                if (list.length > 0) {
                    this.$message({
                        message: list.join('，')+'接入失败',
                        type: 'warning'
                    });
                }
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        switchOut() { // 结束会话
            this.loading = true
            this.$axios({
                method: 'post',
                url: '/common3/wxUserCustomer/outAccess',
                params: {
                    fanId: this.fanId
                }
            }).then(res => {
                this.getSwitchInCustomerList()
                this.messageIndex = ''
                this.nickname = ''
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        autoSwitchOut(row) { // 自动结束会话
            this.getSwitchInCustomerList()
        },
        getLastTime(targetTime) {
            var result = '';
            // 今天开始时间
            var today = moment().format('yyyy-MM-DD');
            // 昨天开始时间
            var yesterday = moment().subtract(1, 'days').format('yyyy-MM-DD');
            // 目标日期
            var targetDate = moment(targetTime).format('yyyy-MM-DD');
            // 目标日期与当前日期是否同一天
            var isToday = moment(targetDate).isSame(today, 'day');
            // 目标日期是否是昨天日期
            var isYesterday = moment(targetDate).isSame(yesterday, 'day');
            if(isToday) {
                result = moment(targetTime).format('HH:mm'); 
            } else if(isYesterday) {
                result = '昨天 '+ moment(targetTime).format('HH:mm'); 
            } else {
                result = moment(targetTime).format('yyyy/MM/DD'); 
            }
            return result;
        },
        tabSwitch(tab) {
            this.loading = true
            this.customerList = []
            if (tab === '待接入') {
                this.accessState = '0'
            }
            if (tab === '已过期') {
                this.accessState = '1'
            }
            this.$axios({
                method: 'get',
                url: '/common3/wxCustomerNews/NewSend',
                params: {
                    jwcnState: "0",
                    accessState: this.accessState
                }
            }).then(res => {
                for (let item of res.data.result) {
                    let content = JSON.parse(item.jwcnContent).Content
                    let Recognition = JSON.parse(item.jwcnContent).Recognition
                    item.content = this.showMsgType(item.jwcnMsgType, content)
                }
                this.customerList = res.data.result
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        getFansList() { // 获取粉丝列表
            this.indexList = []
            this.loading = true
            this.$axios({
                method: 'get',
                url: '/common3/wxFans/getFans',
                params: {
                    nickname: this.findFans
                }
            }).then(res => {
                for (let item of res.data.result) {
                    item.index = item.nickname.spell()[0] // 获取首字母或汉字首字母
                }
                this.fansList = this.group(res.data.result, 'index')
                if (this.fansList[0].data.length === 0) this.fansList.shift() // 如果#分组为空，则删除该分组
                this.fansList.sort((a, b) => { // 按首字母排序
                    return a.key.localeCompare(b.key)
                })
                for (let item of this.fansList) { // 分类集合
                    this.indexList.push(item.key)
                }
                this.fanIndex = ''
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        group(arr, key) { // 粉丝列表按首字母进行分组，汉字获取其拼音首字母
            var map = {}, dest = [{key: '#', data: []}], reg = /[a-z]/i;
            for (var i = 0; i < arr.length; i++) {
                var ai = arr[i];
                if (!map[ai[key].toUpperCase()]) { // 首字母转换成大写
                    if (reg.test(ai[key])) { // 判断首位是否是字母，不是字母的归类到'#'分类里
                        dest.push({
                            key: ai[key].toUpperCase(),
                            data: [ai]
                        });
                        map[ai[key].toUpperCase()] = ai; // 添加新分类
                    } else {
                        dest[0].data.push(ai)
                    }
                }else { // 添加到已有分类里
                    for (var j = 0; j < dest.length; j++) {
                        var dj = dest[j];
                        if (dj.key == ai[key]) {
                            dj.data.push(ai);
                            break;
                        }
                    }
                }
            }
            return dest;
        },
        switchService() { // 查询在线客服
            this.switchServiceDialog = true
            this.loading = true
            this.$axios({
                method: 'get',
                url: '/common3/wxUserCustomer/obtain',
                params: {
                    jwucKfState: '1'
                }
            }).then(res => {
                this.serviceList = res.data.result
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        clickService(index, jwucUserId) { // 选择客服
            this.serviceIndex = index
            this.jwucUserId = jwucUserId
        },
        transfer() { // 转接
            if (this.serviceIndex === '') {
                this.$message.warning('请选择客服')
                return
            }
            this.loading = true
            this.$axios({
                method: 'post',
                url: '/common3/wxUserCustomer/transfer',
                params: {
                    jwucUserId: this.jwucUserId,
                    jwucCustomerIds: String(this.fanId),
                }
            }).then(res => {
                this.$message.success('转接成功')
                this.switchServiceDialog = false
                this.getSwitchInCustomerList()
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        closeSwitchServiceDialog() {
            this.serviceIndex = ''
        },
        getTagsList() { // 获取标签列表
            this.$axios({
                method: 'get',
                url: '/common3/wxTags/list',
            }).then(res => {
                this.tagsList = res.data.result.tagsList
            }).catch(err => {
                this.isError(err, this);
            })
        },
        tagShow(item) { // 打开修改标签
            this.dialogVisible = true
        },
        save() { // 修改粉丝标签
            this.loading = true
            this.$axios({
                method: 'post',
                url: '/common3/wxTagsFans/binding',
                data: {
                    fanIdList: [this.fanIndex.fanId],
                    tagsList: this.fanIndex.tagid_list,
                }
            }).then(res => {
                this.getFanDetail()
                this.dialogVisible = false
                this.$message.success('修改成功')
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        getFanDetail() { // 获取粉丝信息
            this.$axios({
                method: 'get',
                url: `/common3/wxFans/${this.fanIndex.fanId}`,
            }).then(res => {
                this.fanIndex = res.data.result
                for (let i = 0; i < this.fansList.length; i++) {
                    for (let j = 0; j < this.fansList[i].data.length; j++) {
                        if (this.fansList[i].data[j].fanId === this.fanIndex.fanId) {
                            this.fansList[i].data[j] = JSON.parse(JSON.stringify(this.fanIndex))
                            this.$set(this.fansList[i], j, this.fansList[i].data[j])
                        }
                    }
                }
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        synchronous() { // 同步粉丝信息
            this.loading = true
            this.$axios({
                method: 'post',
                url: `/common3/wxFans/syncOneInfo`,
                data: {
                    openidList: [this.fanIndex.openid]
                }
            }).then(res => {
                this.$message.success('同步成功')
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.getFanDetail()
            })
        },
        openRemarkDia() { // 修改备注弹窗
            this.remarkDia = true
            this.remark = JSON.parse(JSON.stringify(this.fanIndex.remark))
        },
        updateRemark() { // 修改备注
            this.loading = true
            this.$axios({
                method: 'get',
                url: `/common3/wxFans/remark`,
                params: {
                    fanId: this.fanIndex.fanId,
                    remark: this.remark
                }
            }).then(res => {
                this.getFanDetail()
                this.remarkDia = false
                this.$message.success('修改备注成功')
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.loading = false
            })
        },
        closeRemarkDia() {
            this.remark = ''
        },
        getPersonalDetails() { // 获取个人客服信息
            this.$axios({
                method: 'get',
                url: '/common3/wxUserCustomer/userCustomer',
                params: {
                    jwucUserId: sessionStorage.getItem('userCoding')
                }
            }).then(res => {
                if (res.data.result.length === 0) return
                this.personalDetails = res.data.result[0]
            }).catch(err => {
                this.isError(err, this);
            })
        },
        async beforeUpload(file){//上传完文件前获取token
            let type = ['image/jpeg', 'image/png', 'image/gif']
            if (type.indexOf(file.type) === -1) {
                this.$message.error('格式不支持')
                return false
            }
            if (file.size / 1024 / 1024 > 10) {
                this.$message.error('上传图片大小不能超过 10MB!')
                return false   //必须返回false
            }
            try {
                let getToken = await this.getToken();
                this.picData.token = getToken;
            } catch (err) {
                console.log(err);
            }
        },
        getToken(){//获取token
            return this.$axios({
                method: 'get',
                url: '/common1/photos/token',
                params: {
                    bucket: '公有'
                }
            }).then(res => {
                return res.data.result;
            })
        },
        success(res, file, fileList, screenshot) {
            //图片的远程路径
            let value;
            if (screenshot) {
                value = res
                this.loading = true
            } else {
                value = "https://pic-gongkai.fangzhizun.com/" + res.key
            }
            this.$axios({
                method: 'post',
                url: '/common3/wxCustomerNews/sendMessage',
                data: {
                    touser: this.openid,
                    jwcnId: this.jwcnId,
                    msgtype: 'image',
                    image: {
                        picurl: value
                    }
                }
            }).then(res => {
                this.receiveAndReply.push({
                    createTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                    image: value,
                    headimgurl: this.personalDetails.jwucKfHeadUrl,
                    jwcnMsgType: 'image',
                    type: 'reply'
                })
                this.replyContent = ''
                for (let item of this.switchInCustomerList) { // 发送成功后刷新自动结束会话时间
                    if (item.jwcnFromUserName == this.openid) {
                        item.minute = 30
                        item.second = 0
                        item.update = false
                        this.$nextTick(() => { // 解决父组件传值给子组件，父组件值改变，子组件不能重新渲染的问题
                            item.update = true
                        })
                    }
                }
            }).catch(err => {
                this.isError(err, this);
            }).finally(() => {
                this.toBottom()
                this.imageDialog = false;
                this.loading = false
            })
        },
        showMsgType(type, data) {
            switch (type) {
                case 'text':
                    return data;
                case 'voice':
                    return '[语音]';
                case 'image':
                    return '[图片]';
                case 'video':
                    return '[视频]';
                default: return
            }
        },
        settingScreenshot() { // 设置截图时是否隐藏当前窗口
            sessionStorage.setItem('checked', this.checked)
        },
        playAudio(voiceIndex, url) { // 播放音频，点击播放中的音频则暂停
            console.log(url)
            let audioBody = document.getElementById('audioBody')
            audioBody.innerHTML = `<audio src="${url}" id="voice"></audio>`
            let audio = document.getElementById('voice')
            audio.play()
            audio.addEventListener("ended", () => { // 播放结束
                this.btnStatus = false
            });
            if (this.voiceIndex !== voiceIndex) { // 切换音频
                this.btnStatus = true
                this.voiceIndex = voiceIndex
            } else { // 双击音频
                this.btnStatus = !this.btnStatus
                if (!this.btnStatus) {
                    setTimeout(() => { // play()之后立即pause()，会报错，所以加了定时器
                        audio.pause()
                    }, 100)
                }
            }
        },
        onContextmenu(index) { // 右键菜单结束会话
            this.transferIndex = index; // tranfer index to child component
            event.preventDefault();
            var x = event.clientX;
            var y = event.clientY;
            this.contextMenuData.axis = {
                x,
                y
            };
        },
        Handler_A(item) { // 右键菜单结束会话
            this.fanId = item.jwcnFanId
            this.switchOut()
        },
        turnTheText(item, index) { // 右键菜单语音转文字
            this.contextMenuData2.menulists = 
                this.showTextList.indexOf(index) === -1 ? [{btnName: '语音转文字',fnHandler: "Handler2"}] : [{btnName: '取消转文字',fnHandler: "Handler3"}]
            this.voiceIndex1 = index
            if (item.jwcnMsgType === 'voice') {
                this.transferIndex2 = index; // tranfer index to child component
                event.preventDefault();
                var x = event.clientX;
                var y = event.clientY;
                this.contextMenuData2.axis = {
                    x,
                    y
                };
            }
        },
        Handler_B(item) { // 右键菜单语音转文字
            this.showTextList.push(this.voiceIndex1)
        },
        Handler_C(item) { // 右键菜单取消转文字
            this.showTextList.splice(this.showTextList.indexOf(this.voiceIndex1), 1)
        },
        editAvatar() {
            this.editAvatarDialog = true
            this.option.img = this.avatarURL
        },
        // 保存头像修改
        saveEditAvatar() {
            this.finish('blob')
        },
        // 放大/缩小
        changeScale(num) {
            num = num || 1;
            this.$refs.cropper.changeScale(num);
        },
        // 左旋转
        rotateLeft() {
            this.$refs.cropper.rotateLeft();
        },
        // 右旋转
        rotateRight() {
            this.$refs.cropper.rotateRight();
        },
        // 保存上传图片
        async finish(type) {
            this.loading = true
            let token = await this.getToken();
            if (type === 'blob') {
                this.$refs.cropper.getCropBlob((data) => {
                    // console.log(data)
                    // this.avatarURL = window.URL.createObjectURL(data)
                    // 访问接口保存到数据库写这儿!
                    this.blobToBase64(data).then(res => {
                        // 转化后的base64
                        var pic = res.replace('data:image/png;base64,', '');
                        var url = "http://up-z2.qiniu.com/putb64/-1"; //非华东空间需要根据注意事项 1 修改上传域名
                        var xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState==4){
                                // console.log("https://pic-gongkai.fangzhizun.com/" + JSON.parse(xhr.responseText).key)
                                this.uploadToServer("https://pic-gongkai.fangzhizun.com/" + JSON.parse(xhr.responseText).key)
                            }
                            if (xhr.status === 404) {
                                this.loading = false
                            }
                        }
                        xhr.open("POST", url, true);
                        xhr.setRequestHeader("Content-Type", "application/octet-stream");
                        xhr.setRequestHeader("Authorization", "UpToken " + token);
                        xhr.send(pic);
                    })
                })
            } else {
                this.$refs.cropper.getCropData((data) => {
                    //访问接口保存到数据库写这儿!
                })
            }
        },
        //上传头像
        uploadToServer(url) {
            this.$axios({
                method: 'PUT',
                url: '/common3/wxUserCustomer/userCustomerImg',
                params: {
                    jwucKfHeadUrl: url
                }
            }).then(res => {
                this.editAvatarDialog = false
                this.getPersonalDetails()
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    resolve(e.target.result);
                };
                // readAsDataURL
                fileReader.readAsDataURL(blob);
                fileReader.onerror = () => {
                    reject(new Error('blobToBase64 error'));
                };
            });
        },
        // 实时预览函数
        realTime(data) {
            this.previews = data
        },
        // 下载图片
        down(type) {
            var aLink = document.createElement('a')
            aLink.download = 'author-img'
            if (type === 'blob') {
                this.$refs.cropper.getCropBlob((data) => {
                    aLink.href = window.URL.createObjectURL(data)
                    aLink.click()
                })
            } else {
                this.$refs.cropper.getCropData((data) => {
                    aLink.href = data;
                    aLink.click()
                })
            }
        },
        // 更换头像--上传本地图片
        uploadImg(file) {
            var _this = this;
            var reader = new FileReader();
            reader.onload = (e) => {
                console.log(e)
                let data;
                if (typeof e.target.result === 'object') {
                    // 把Array Buffer转化为blob 如果是base64不需要
                    data = window.URL.createObjectURL(new Blob([e.target.result]))
                } else {
                    data = e.target.result
                }
                _this.option.img = data
            }
            // 转化为base64
            // reader.readAsDataURL(file.raw)
            // 转化为blob
            reader.readAsArrayBuffer(file.raw);
        },
    },
    destroyed() {
      this.websocket.close();
      URL.revokeObjectURL();
    },
};
</script>

<style lang="scss" scoped>
.message_management{
    /deep/.upload-demo {
        display: inline;
    }
    .emoji {
        width: 15px;
        height: 15px;
        overflow: hidden;
        vertical-align:middle;
    }
    /deep/.el-table .cell {
        line-height: 35px;
    }
    .menu {
        height: 89.6vh;
        margin-top: 2px;
        .el-container /deep/.el-header {
            height: 6.9vh !important;
        }
        .header {
            .search {
                box-sizing: border-box;
                float: left;
                width: 280px;
                padding: 20px 10px 17px;
                background: #e8e7e7;
                /deep/.el-input__inner {
                    background: #dbd9d8;
                }
                /deep/.el-input__inner:focus {
                    background: #ffffff;
                }
                .search_input /deep/input::-webkit-input-placeholder,/deep/.el-input__prefix, .el-input__suffix {
                    color: #818181;
                }
            }
            .name {
                background: #f5f5f5;
                height: 6.9vh;
                font-size: 22px;
                .nick_name {
                    position: relative;
                    left: 30px;
                    top: 20px;
                }
                .chat_header_icons {
                    float: right;
                    .chat_header_icon {
                        cursor: pointer;
                    }
                    .exit {
                        position: relative;
                        right: 30px;
                        top: 4px;
                        display: block;
                        width: 25px;
                        height: 20px;
                        background: transparent url('../../assets/weChat/exit_g.png') no-repeat 0 0;
                    }
                    .exit:hover {
                        background: transparent url('../../assets/weChat/exit_d.png') no-repeat 0 0;
                    }
                    .transfer {
                        position: relative;
                        left: -70px;
                        top: 25px;
                        display: block;
                        width: 25px;
                        height: 25px;
                        background: transparent url('../../assets/weChat/transfer_g.png') no-repeat 0 0;
                    }
                    .transfer:hover {
                        background: transparent url('../../assets/weChat/transfer_d.png') no-repeat 0 0;
                    }
                }
            }
        }
        .no_msg {
            background: #f5f5f5;
            position: relative;
            img {
                width: 80px;
                height: 80px;
                position: absolute;
                top: 40%;
                left: 50%;
            }
            span {
                color: #9696a2;
                position: absolute;
                top: 50%;
                left: 50%;
            }
        }
        .message_list {
            height: 82.7vh; 
            background: #e8e7e7;
            .message {
                padding: 10px 12px;
                height: 47px;
                cursor: pointer;
                img {
                    border-radius: 3px;
                    float: left;
                    width: 47px;
                    height: 47px;
                    margin-right: 12px;
                }
                .user_name {
                    font-size: 15px;
                    position: relative;
                    top: 2px;
                    span {
                        width: 130px;
                        margin-right: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }
                    
                    time {
                        position: absolute;
                        top: 3px;
                        right: 0;
                        color: #999999;
                        font-size: 10px;
                    }
                }
                .msg_lessen {
                    font-size: 14px;
                    color: #999;
                    position: relative;
                    top: 6px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }
            .message:hover {
                background: #c9c8c7;
            }
        }
        .menu_icon {
            img{
                width: 40px;
                height: 40px;
                margin-top: 20px;
            }
            .personal {
                background: coral;
                display: flex;
                justify-content: center;
                .personal_data {
                    flex: 0.7;
                }
                .personal_img {
                    flex: 0.3;
                }
            }
        }
        .fans_list {
            height: 89.7vh;
            background: #e8e6e5;
            
            /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
            -ms-overflow-style: none;
            /*火狐下隐藏滚动条*/
            overflow: -moz-scrollbars-none;
            .search {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 999;
                box-sizing: border-box;
                width: 280px;
                padding: 20px 10px 17px;
                background: #e8e7e7;
                /deep/.el-input__inner {
                    background: #dbd9d8;
                }
                /deep/.el-input__inner:focus {
                    background: #ffffff;
                }
                .search_input /deep/input::-webkit-input-placeholder,/deep/.el-input__prefix, .el-input__suffix {
                    color: #818181;
                }
            }
            .list {
                margin-top: 65px;
            }
            .fans_col {
                padding: 5px;
                height: 50px;
            }
            .fans_col_click {
                background: #c5c5c5;
            }
            .fans_col:hover {
                background: #dddbda;
                cursor: pointer;
            }
            .fans_avatar {
                float: left;
                margin: 5px;
            }
            .fan_name {
                font-size: 15px;
                line-height: 50px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
            /deep/.van-index-bar__sidebar {
                position: fixed;
                left: 515px;
                right: unset;
            }
            /deep/.van-index-anchor--sticky {
                position: fixed;
                top: 65px;
            }
        }
        .fans_list::-webkit-scrollbar{
            display:none;
        }
        .main {
            background: #f5f5f5;
            border-top: 1px #e7e7e7 solid;
            font-size: 15px;
            height: 62.7vh !important;
            .loading {
                display: block;
                width: 20px;
                margin: 0 auto;
            }
            .chatPop1 p {
                background-color: #fff;
                padding: 6px 9px;
                display: inline-block;
                border-radius: 3px;
                margin: 0px 0 10px 0px;
                position: relative;
                border: 1px solid #ededed;
                max-width: 290px;
                word-break:break-all;
            }
            .chatPop1 .voice {
                background-color: #fff;
                padding: 6px 9px 0;
                display: inline-block;
                border-radius: 3px;
                margin: 0px 0 0px 5px;
                position: relative;
                max-width: 290px;
                word-break:break-all;
            }
            .chatPop1 p::after {
                content: '';
                border: 8px solid #f5f5f5;
                border-right: 8px solid #fff;
                position: absolute;
                top: 8px;
                left: -16px;
            }
            .chatPop1 .voice::after {
                content: '';
                border: 8px solid #f5f5f5;
                border-right: 8px solid #fff;
                position: absolute;
                top: 8px;
                left: -16px;
            }
            .chatPop1 .img, video {
                position: relative;
                max-width: 290px;
                border-radius: 3px;
            }
            .chatPop1 .voice_time {
                color: darkgray;
                position: relative;
                left: 10px;
                // top: -8px;
                bottom: 8px;
            }
            .chatPop2 p {
                background-color: #9eea6a;
                padding: 6px 9px;
                display: block;
                border-radius: 3px;
                margin: 0px 15px 10px 0px;
                position: relative;
                border: 1px solid #E3E3E3;
                max-width: 290px;
                float: right;
                word-break:break-all;
            }
            .chatPop2 p::after {
                content: '';
                border: 8px solid #f5f5f5;
                border-right: 8px solid #9eea6a;
                position: absolute;
                top: 8px;
                right: -16px;
                transform: rotateY(180deg)
            }
            .chatPop2 .img {
                position: relative;
                right: 16px;
                max-width: 290px;
                float: right;
                border-radius: 3px;
            }
            .chatPop2 .angle-wrapper {
                position: relative;
                right: 16px;
                float: right;
                width: 300px;
                height: 200px;
                position: relative;
                border: 2px solid #cccccc;
                background-image: url('https://pic3.zhimg.com/v2-eea8f9b170c7d1a6d53706bef942f6b6_540x450.jpeg');
            }
            .chatPop2 .angle-wrapper:before {
                content: "";
                width: 0;
                height: 0;
                border: 18px solid transparent;
                border-left-color: #9eea6a;
                position: absolute;
                left: 100%;
                top: 10%;
                margin-top: -18px;
            }
            .time_out {
                text-align: center;
                color: #fff;
                background: #cecdcb;
                border: 0;
                i {
                    font-size: 20px;
                    margin: 0 5px;
                    position: relative;
                    top: 3px;
                }
                a {
                    color: #4a90e2;
                    cursor: pointer;
                }
            }
        }
        .footer {
            border-top: 1px #e7e7e7 solid;
            height: 20vh !important;
            .reply_button {
                padding: 8px 0 10px;
                .reply_emoji {
                    float: left;
                    display: block;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    background: transparent url('../../assets/weChat/emoj_g.png') no-repeat 0 0;
                }
                .reply_emoji:hover {
                    background: transparent url('../../assets/weChat/emoj_d.png') no-repeat 0 0;
                }
                .reply_image {
                    float: left;
                    display: block;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    background: transparent url('../../assets/weChat/image_g.png') no-repeat 0 0;
                }
                .reply_image:hover {
                    background: transparent url('../../assets/weChat/image_d.png') no-repeat 0 0;
                }
                .screenshot {
                    float: left;
                    display: block;
                    width: 25px;
                    height: 30px;
                    cursor: pointer;
                    background: transparent url('../../assets/weChat/cut_g.png') no-repeat 0 0;
                }
                .screenshot:hover {
                    background: transparent url('../../assets/weChat/cut_d.png') no-repeat 0 0;
                }
                .down {
                    float: left;
                    display: block;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                    margin-top: 8px;
                    background: transparent url('../../assets/weChat/down_g.png') no-repeat 0 0;
                }
                .down:hover {
                    background: transparent url('../../assets/weChat/down_d.png') no-repeat 0 0;
                }
            }
            /deep/.el-textarea__inner {
                padding: 0;
                border: 0;
                resize: none;
            }
            .reply_submit {
                position: fixed;
                bottom: 15px;
                right: 30px;
            }
        }
        .switch_in {
            cursor: pointer;
            color: #4a90e2;
        }
        .waiting_panel {
            margin-left: 2px;
            border-bottom: 1px #ebeef5 solid;
            padding: 2vh;
            .switch_btn {
                float: right;
            }
            .setting_panel {
                position: relative;
                padding: 0 35px;
                cursor: pointer;
            }
            .setting_panel:first-child:after {
                content: "";
                position: absolute;
                right: -1px;
                top: 4px;
                height: 12px;
                border-right: 2px solid #969696;
            }
            .selected {
                color: #09bb07;
            }
        }
        .no_fan {
            background: #f5f5f5;
            position: relative;
            img {
                width: 80px;
                height: 80px;
                position: absolute;
                top: 45%;
                left: 50%;
            }
        }
        .has_fan {
            background: #f5f5f5;
            .fan_data {
                margin: 15vh auto;
                width: 450px;
                .fans_avatar {
                    margin-right: 20px;
                }
                .el-form-item {
                    margin: 0;
                }
                .el-form {
                    margin-top: 30px;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    border-top: 1px solid #e7e7e7;
                    border-bottom: 1px solid #e7e7e7;
                }
                .man {
                    color: #46b6ef;
                }
                .woman {
                    color: #f37e7d;
                }
            }
        }
    }
    /deep/.el-tabs__nav-scroll {
        background: #25282b;
    }
    /deep/.el-tabs--left .el-tabs__header.is-left {
        margin: 0;
    }
    /deep/.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
        height: 80px;
        width: 80px;
    }
    /deep/.el-tabs--left.el-tabs--card .el-tabs__item.is-left,/deep/.el-tabs--left.el-tabs--card .el-tabs__nav {
        border: 0;
    }
    .card_row {
        /deep/.el-col-8 {
            margin-bottom: 15px;
        }
        .service_card {
            height: 54px;
            width: 184px;
            border: #dcdcdc 1px solid;
            padding: 6px;
            display: flex;
            justify-content: center;
            background: #fff;
            cursor: pointer;
            position: relative;
            .service_avatar {
                flex: 0.3;
            }
            .service_detail {
                flex: 0.7;
                .service_nickname {
                    margin: 5px;
                }
                .service_wx {
                    color: #96a3c2;
                    margin-left: 5px;
                }
            }
            .selected_icon {
                background: transparent url('../../assets/weChat/emoji_z2c1a46.png') no-repeat 0 -120px;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 24px;
                height: 24px;
            }
        }
        .selected {
            border:#09bb07 1px solid;
        }
    }
    // 语音播放样式start
    .wave {
        width: 30px;
        height: 30px;
        box-sizing: border-box;                
        position:relative;  
        overflow: hidden;
        transform: rotate(135deg);
        .wifi-circle {
            border: 3px solid #818181;
            border-radius: 50%;
            position: absolute;
        }
        .first {
            width: 5px;
            height: 5px;
            background: rgb(51, 50, 50);
            top: 25px;
            left: 25px;
        }
        .second {
            width: 25px;
            height: 25px;
            top: 17px;
            left: 17px;
        }
        .third {
            width: 40px;
            height: 40px;
            top: 10px;
            left: 10px;
        }
    }
    //不同之处在于，是否有keyframes动画效果的显现。
    .static{
        width: 30px;
        height: 30px;
        box-sizing: border-box;                
        position:relative;  
        overflow: hidden;
        transform: rotate(135deg);
        .wifi-circle {
            border: 3px solid #818181;
            border-radius: 50%;
            position: absolute;
        }
        @keyframes fadeInOut {
            0% {
                opacity: 0; /*初始状态 透明度为0 */
            }
            100% {
                opacity: 1; /*结尾状态 透明度为1 */
            }
        }
        .first {
            width: 5px;
            height: 5px;
            background: rgb(51, 50, 50);
            top: 25px;
            left: 25px;
        }

        .second {
            width: 25px;
            height: 25px;
            top: 17px;
            left: 17px;
            animation: fadeInOut 1s infinite 0.2s;
        }

        .third {
            width: 40px;
            height: 40px;
            top: 10px;
            left: 10px;
            animation: fadeInOut 1s infinite 0.4s;
        }
    }
    // 语音播放样式end
    .previewBox {
        text-align: center;
        margin-left: 60px;
    }
 
    .preview {
        width: 150px;
        height: 150px;
        margin: 0px auto 20px auto;
        border-radius: 5px;
        border: 1px solid #ccc;
        background-color: #ccc;
        overflow: hidden;
    }
 
    .cropper {
        width: 260px;
        height: 260px;
    }
}
</style>