import {
    isMacintosh,
    rgb2value,
    NiuniuCaptureObject,
    emCaptureFailed,
    emCaptureUnknown
} from './niuniucapture.js'
import axios from 'axios'
import store from '../../../store'
import * as qiniu from 'qiniu-js'

var captureObj = null;
var downloadUrl = 'http://www.ggniu.cn/download/CaptureInstall.exe?';

/*
用于初始化牛牛截图控件，此函数需要在页面加载完成后立即调用 
在此函数中，您可以设置相关的截图的UI控制，如，画笔大小、边框颜色等等 【这部分信息在niuniucapture.js中也有默认值，直接修改默认值也可 】
*/
function Init()
{
	if(isMacintosh())
	{
		downloadUrl = 'http://www.ggniu.cn/download/CaptureInstall.dmg?';
	}
    captureObj = new NiuniuCaptureObject();
    captureObj.NiuniuAuthKey = "niuniu";
    //此处可以设置相关参数 
    captureObj.TrackColor = rgb2value(255, 0, 0);
    captureObj.EditBorderColor = rgb2value(0, 0, 255);
    
    //设置工具栏的TOOLTIP  
    //captureObj.ToolTipText = "tipRectangle|tipCircle|tipArrow|tipBrush|tipGlitter|tipMosaic|tipText|tipUndo|tipSave|tipCancel|tipFinish|Finish";
    
    //设置控件加载完成以及截图完成的回调函数
    captureObj.FinishedCallback = OnCaptureFinishedCallback;
    captureObj.PluginLoadedCallback = PluginLoadedCallback;
	captureObj.VersionCallback = VersionCallback;
    
    //初始化控件 
    captureObj.InitNiuniuCapture();
}

//用于返回控件的版本号
function VersionCallback(ver)
{
	//captureObj.Version;
	//可以在此根据最新的版本号与控件返回的版本号对比，决定是否要提示升级  
	//alert(ver);
}
/*
当控件成功加载后回调的的函数，您可以在此控制相应的UI显示  
*/
function PluginLoadedCallback(success)
{
}

/*
截图入口函数，用于控制UI标签的显示 
*/
function StartCapture(hideFlag)
{
    var captureRet = Capture(hideFlag);
    //从返回值来解析显示  	
    if(captureRet == emCaptureFailed || captureRet == emCaptureUnknown)
    {		
        return true
    }
}

/*
此函数是根据在测试页面上的不同选项来进行截图，在实际应用中，您只需要根据您实际需要的截图模式，传入相应的参数即可 
*/
function Capture(hideFlag) 
{
	var defaultName = "1.jpg"; //此处为了防止上传的数据过大，建议使用JPG格式 
    var autoFlag = $("#captureselectSize").attr("checked")=="checked" ? 1 : 0;
    if(autoFlag == 0)
    {
        return captureObj.DoCapture("1.jpg", hideFlag, 0, 0, 0, 0, 0);
    }
    else
    {
        autoFlag = $('#getimagefromclipboard').is(':checked') ? 4 : 1; // 4 从剪贴板获取图片
		if(autoFlag == 4)
		{
		    return captureObj.DoCapture("", 0, 4, 0, 0, 0, 0);
		}
		autoFlag = $('#showprewindow').is(':checked') ? 3 : 1; // 3 截图时弹出提示窗口
		if(autoFlag == 3)
		{
		    //此时如果x, y, width, height全为0，则表示预截图窗口点击“开始截图”时，手工先把区域
		    //x, y, width, height全为1，则表示预截图窗口点击“开始截图”时，自动截取整个桌面
		    //其他情况，则自动截取 x, y, width, height 指定的区域  
		    return captureObj.DoCapture("1.jpg", hideFlag, 3, 0, 0, 0, 0);
		}
		autoFlag = $('#fullscreen').is(':checked') ? 2 : 1; // 2 截取当前桌面
		if(autoFlag == 2)
		{
			return captureObj.DoCapture("1.jpg", hideFlag, 2, 0, 0, 0, 0);
		}
        else
		{
			return captureObj.DoCapture("1.jpg", hideFlag, 1, $('#xpos').val(), $('#ypos').val(), $('#width').val(), $('#height').val());
		}
    }            
}

/*
此处是截图后的回调函数，用于将截图的详细信息反馈回来，你需要调整此函数，完成图像数据的传输与显示  
*/
function OnCaptureFinishedCallback(type, x, y, width, height, info, content,localpath) 
{
    if(type < 0)
    {
        //提示用户需要重新安装控件
        return;
    }
    switch(type)
    {
        case 1:
        {
            console.log('截图完成： x:' + x + ',y:' + y + ',widht:' + width + ',height:' + height);
	        UploadCaptureData(content);  
	        break;
        }
        case 2:
        {
            console.log('您取消了截图');     
            break;        
        }
        case 3:
        {
            console.log('您保存了截图到本地： x:' + x + ',y:' + y + ',widht:' + width + ',height:' + height)
	        UploadCaptureData(content);
	        break;
        }
        case 4:
        {
            if(info =='0')
            {
	            UploadCaptureData(content);
            }        
	        break;
        }
    }                 
}

//控制上传
function UploadCaptureData(content)
{
    axios({
        method: 'get',
        url: '/common1/photos/token',
        params: {
            bucket: '公有'
        }
    }).then(res => {
        // var pic = content;
        var fileName = 'xc' + Date.parse(new Date()) + '.jpg';
        // var url = "http://up-z2.qiniu.com/putb64/-1"; //非华东空间需要根据注意事项 1 修改上传域名
        // var xhr = new XMLHttpRequest();
        // xhr.onreadystatechange=function(){
        //     if (xhr.readyState==4){
        //         store.dispatch('setImageUrlName', "https://pic-gongkai.fangzhizun.com/" + JSON.parse(xhr.responseText).key)
        //     }
        // }
        // xhr.open("POST", url, true);
        // xhr.setRequestHeader("Content-Type", "application/octet-stream");
        // xhr.setRequestHeader("Authorization", 'UpToken ' + res.data.result);
        // xhr.send(pic);
        //调用
        var blob = dataURLtoBlob(content);
        // 第一个参数是文件对象（Blob），第二个参数是文件名称（这个名称不能相同，命名时候请加入时间搓，确保文件名称唯一），第三个参数是 token
        const observable = qiniu.upload(blob, fileName, res.data.result, {
            fname: fileName,
            params: {},
            mimeType: [] || null
        }, {
            useCdnDomain: true, // cdn
            unique_names: false, // 唯一名称
            region: qiniu.region.z2 // 根据区域设置上传的域名
        });
        observable.subscribe({
            next (res) {
                // 上传的进度，可以获得总大小，已传大小，上传百分比进度
            },
            error (err) {
                console.log('失败');
                console.log(err);
            },
            complete (res) {
                // 成功后，回调操作
                console.log('上传成功');
                store.dispatch('setImageUrlName', "https://pic-gongkai.fangzhizun.com/" + res.key)
            }
        });
    })
}

//将base64转换为blob
function dataURLtoBlob(dataurl) { 
    var  bstr = atob(dataurl)
    var  n = bstr.length
    var  u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "image/jpeg" });
}

export {
    Init,
    StartCapture,
}